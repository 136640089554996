/* eslint-disable import/no-named-as-default */

import { BrowserRouter, Routes, Route } from "react-router-dom";
import ServiceUnavailable from "../503/index";
import NotFound from "../404/index";
import "./app.css";
import "../../fonts/Nunito.ttf";
import "bootstrap-icons/font/bootstrap-icons.css";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"*"} element={<ServiceUnavailable />} />
        <Route path={"/404"} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
