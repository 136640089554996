import { useMediaQuery } from "react-responsive";

const ServiceUnavailable = (props) => {
  const isMobileOnly = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <div>
      {!isMobileOnly ? (
        <div className="container_desktop">
          <div className="d-flex_container">
            <div>
              <svg className="geat__icon" viewBox="0 0 36 36">
                <path
                  d="m 23.94,18.78 c .03,-0.25 .05,-0.51 .05,-0.78 0,-0.27 -0.02,-0.52 -0.05,-0.78 l 1.68,-1.32 c .15,-0.12 .19,-0.33 .09,-0.51 l -1.6,-2.76 c -0.09,-0.17 -0.31,-0.24 -0.48,-0.17 l -1.99,.8 c -0.41,-0.32 -0.86,-0.58 -1.35,-0.78 l -0.30,-2.12 c -0.02,-0.19 -0.19,-0.33 -0.39,-0.33 l -3.2,0 c -0.2,0 -0.36,.14 -0.39,.33 l -0.30,2.12 c -0.48,.2 -0.93,.47 -1.35,.78 l -1.99,-0.8 c -0.18,-0.07 -0.39,0 -0.48,.17 l -1.6,2.76 c -0.10,.17 -0.05,.39 .09,.51 l 1.68,1.32 c -0.03,.25 -0.05,.52 -0.05,.78 0,.26 .02,.52 .05,.78 l -1.68,1.32 c -0.15,.12 -0.19,.33 -0.09,.51 l 1.6,2.76 c .09,.17 .31,.24 .48,.17 l 1.99,-0.8 c .41,.32 .86,.58 1.35,.78 l .30,2.12 c .02,.19 .19,.33 .39,.33 l 3.2,0 c .2,0 .36,-0.14 .39,-0.33 l .30,-2.12 c .48,-0.2 .93,-0.47 1.35,-0.78 l 1.99,.8 c .18,.07 .39,0 .48,-0.17 l 1.6,-2.76 c .09,-0.17 .05,-0.39 -0.09,-0.51 l -1.68,-1.32 0,0 z m -5.94,2.01 c -1.54,0 -2.8,-1.25 -2.8,-2.8 0,-1.54 1.25,-2.8 2.8,-2.8 1.54,0 2.8,1.25 2.8,2.8 0,1.54 -1.25,2.8 -2.8,2.8 l 0,0 z"
                  fill="#141414"
                ></path>
              </svg>
            </div>
            <div>
              <span className="font-Nunito fs64 mb-3">Обновление сайта</span>
              <hr />
              <p className="fs24 font-Roboto">Пожалуйста, зайдите позже</p>
            </div>
          </div>
          <div className="block_header">
            <div className="block_content">
              <span className="fs38 font-Nunito text-wh">
                В данный момент, сайт находится на техническом обслуживании
              </span>
              <p className="text-wh font-Roboto">
                Приносим извинения за временные неудобства
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="container_mobile">
          <div className="d-flex_container_mobile">
            <div>
              <span className="font-Nunito fs24 mb-3">Обновление сайта</span>
              <hr />
              <p className="fs16 font-Roboto">Пожалуйста, зайдите позже</p>
            </div>
          </div>
          <div className="block_header_mobile">
            <div className="block_content_mobile">
              <span className="fs18 font-Nunito text-wh">
                В данный момент, сайт находится на техническом обслуживании
              </span>
              <p className="fs14 text-wh font-Roboto">
                Приносим извинения за временные неудобства
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceUnavailable;
