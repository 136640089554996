const NotFound = (props) => {
  return (
    <div>
      <span className="font-Nunito fs128">404</span>
      <br />
      <span className="font-Roboto fs38">СТРАНИЦА НЕ НАЙДЕНА</span>
    </div>
  );
};

export default NotFound;
